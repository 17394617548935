import { Box, Flex } from "@chakra-ui/react";
import { useMemo } from "react";
import { useCountdown } from "../../../../hooks/useCountdown";

export const Timeline = () => {
  const currentDate = useCountdown(2024, 12, 6, 16);
  const nextDate = useCountdown(2025, 1, 3, 16);

  let date = useMemo(() => {
    if (currentDate.passed) {
      return nextDate;
    } else {
      return currentDate;
    }
  }, [currentDate, nextDate]);

  return (
    <Flex flexDir="column" align="center" justify="center" py="40px">
      <Flex>Next rewards in:</Flex>
      <Flex justify="center">
        <Flex fontSize={{ base: "40px", md: "50px" }} justify="center">
          <Box w="auto">{date.days}</Box>
          <Box color="brand.500">d&nbsp;</Box>
          <Box w="auto">{date.hours}</Box>
          <Box color="brand.500">h&nbsp;</Box>
          <Box w="auto">
            {date.minutes < 10 ? `0${date.minutes}` : date.minutes}
          </Box>
          <Box color="brand.500">m&nbsp;</Box>
          <Box w="auto">
            {date.seconds < 10 ? `0${date.seconds}` : date.seconds}
          </Box>
          <Box color="brand.500">s&nbsp;</Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
